import { useEventListener } from '@vueuse/core';
import { getCookieValue } from '@/js/helpers/cookieHelpers';
import { SiteIdEnum } from '@/js/enums/siteIds';
import { sendAnchorTrackingData, sendPageViewData } from './api';

const getGoogleAnalyticsClientId = (cookieValue : string): string => {
    if (!cookieValue) {
        return null;
    }
    const parts = cookieValue.split('.');

    return parts.length > 3 ? `${parts[2]}.${parts[3]}` : cookieValue;
};

const addTrackingToAnchors = () => {
    const anchorElements = document.querySelectorAll('a');
    const googleCookie = getCookieValue('_ga');

    const addTracking = (anchor: HTMLAnchorElement) => {
        if (googleCookie) {
            const cid = getGoogleAnalyticsClientId(googleCookie);
            const anchorText = anchor.textContent.trim();
            const { category, position } = anchor.dataset;

            if (anchorText || category) {
                sendAnchorTrackingData({
                    anchorText,
                    category,
                    cid,
                    position,
                    url: `${window.location.origin}${window.location.pathname}`,
                    sid: SiteIdEnum.SITE_ID,
                });
            }
        }
    };

    anchorElements.forEach((anchor) => {
        useEventListener(anchor, 'click', () => {
            addTracking(anchor);
        });
    });
};

const initializePageViewData = () => {
    const fpvCookie = getCookieValue('fpv');

    if (fpvCookie) {
        const googleCookie = getCookieValue('_ga');
        const cid = getGoogleAnalyticsClientId(googleCookie);

        const INTERVAL_TIMEOUT = 500;
        const MAX_INTERVAL_LOOPS = 20;
        let intervalLoopCount = 0;

        const intervalID = setInterval(async () => {
            intervalLoopCount += 1;
            if (cid) {
                try {
                    await sendPageViewData({
                        cid,
                        url: JSON.stringify(window.location.origin + window.location.pathname),
                        previous_url: JSON.stringify(document.referrer),
                        sid: SiteIdEnum.SITE_ID,
                    });
                } finally {
                    clearInterval(intervalID);
                }
            }

            if (intervalLoopCount >= MAX_INTERVAL_LOOPS) {
                clearInterval(intervalID);
            }
        }, INTERVAL_TIMEOUT);
    }
};

useEventListener(document, 'DOMContentLoaded', () => {
    initializePageViewData();
    addTrackingToAnchors();
});
