import useAxiosRepository from '../../../repositories/BaseRepository';

const axiosRepo = useAxiosRepository();

export const sendPageViewData = async (
    payload: {
        cid: string,
        url: string,
        previous_url: string,
        sid: number,
    },
) => {
    const api = 'https://www.vulpr.com/api/pageview';

    await axiosRepo.post(api, payload, { useBaseURL: false });
};

export const sendAnchorTrackingData = async (
    payload: {
        anchorText: string,
        category: string,
        cid: string,
        position: string,
        url: string,
        sid: number,
    },
) => {
    const api = 'https://www.vulpr.com/api/event';

    await axiosRepo.post(api, payload, { useBaseURL: false });
};
